<template>
  <div>
    <page-toolbar title="Edit CPAR">
      <template slot="action">
        <v-btn :loading="loading" v-if="cpar_header.status == 'open'" text @click="saveData()">Save</v-btn>
      </template>
    </page-toolbar>
    <v-container>
      <page-title :title="cpar_header.document_no_">
        <template slot="action">
          <v-btn class="ml-n1 mx-1" @click="$router.go(-1)">Back</v-btn>
          <rightbar title="View Comments" hideToolbar class="d-inline" v-if="(typeof cpar_header.status !== 'undefined')">
            <template v-slot:activator="{ on }">
              <v-btn class="mx-1" v-on:click="on">Comments</v-btn>
            </template>
            <comment-list :cpar_header_id="parseInt(cpar_header.id)"></comment-list>
          </rightbar>
          <v-btn :loading="loading" v-if="cpar_header.status == 'open'" class="mx-1" @click="saveData()" color="primary">Save</v-btn>
        </template>
      </page-title>

      <v-row v-if="(loading == false) && (typeof cpar_header.status !== 'undefined') && (cpar_header.status != 'open')">
        <v-col>
          <v-alert color="info" dark>
            <p>Document status must be open! <a href="javascript:;" @click="$router.go(-1)">Back</a> </p>
          </v-alert>
        </v-col>
      </v-row>

      <v-row v-if="(notFound == true) && (typeof cpar_header.status === 'undefined')">
        <v-col>
          <v-alert color="warning" dark>
            <p>Document status must be open! <a href="javascript:;" @click="$router.go(-1)">Back</a> </p>
          </v-alert>
        </v-col>
      </v-row>

      <v-row v-if="cpar_header.status == 'open'">
        <v-col cols="12">
          <!-- General -->
          <card-expansion title="General" class="mb-3" :isMinimize="false">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field dense disabled v-model="cpar_header.document_no_" placeholder="No." label="No." readonly filled outlined hide-details class="mb-2"></v-text-field>
                <v-text-field dense disabled v-model="cpar_header.document_type" placeholder="Document Type" label="Document Type" readonly filled outlined hide-details class="mb-2"></v-text-field>
                <v-text-field dense disabled v-model="cpar_header.area_code" placeholder="Area Code" label="Area Code" readonly filled outlined hide-details class="mb-2"></v-text-field>
                <v-text-field dense disabled v-model="cpar_header.created_by_username" placeholder="Created By" label="Created By" readonly filled outlined hide-details class="mb-2"></v-text-field>
                <v-select dense v-model="cpar_header.cpar_source_id" hide-details class="mb-2" placeholder="Sumber CPAR" label="Sumber CPAR" outlined :items="cpar_sources" item-text="name" item-value="id"></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field dense disabled v-model="cpar_header.document_date" placeholder="Doc. Date" label="Doc. Date" readonly filled outlined hide-details class="mb-2"></v-text-field>
                <v-text-field dense disabled v-model="cpar_header.source_document" placeholder="Source Document" label="Source Document" readonly filled outlined hide-details class="mb-2"></v-text-field>
                <v-text-field dense disabled v-model="cpar_header.status" placeholder="Status" label="Status" readonly filled outlined hide-details class="mb-2"></v-text-field>
                <v-text-field dense disabled v-model="cpar_header.created_date" placeholder="Created Date" label="Created Date" readonly filled outlined hide-details class="mb-2"></v-text-field>
                <v-select dense v-model="cpar_header.business_unit" hide-details class="mb-2" placeholder="Business Unit" label="Business Unit" outlined :items="business_units" item-text="name" item-value="id"></v-select>
              </v-col>
            </v-row>
            <v-divider class="mb-3"></v-divider>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field dense v-model="cpar_header.company_name" placeholder="Nama Perusahaan" label="Nama Perusahaan" outlined hide-details class="mb-2"></v-text-field>
                <v-text-field dense v-model="cpar_header.company_project" placeholder="Nama Proyek" label="Nama Proyek" outlined hide-details class="mb-2"></v-text-field>
                <v-text-field dense v-model="cpar_header.sales_order_no_" placeholder="Sales Order No." label="Sales Order No." outlined hide-details class="mb-2"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field dense v-model="cpar_header.location" placeholder="Lokasi" label="Lokasi" outlined hide-details class="mb-2"></v-text-field>
                <v-select dense v-model="cpar_header.cpar_type" placeholder="Jenis CPAR" label="Jenis CPAR" :items="cpar_types" item-value="value" item-text="text" outlined hide-details class="mb-2"></v-select>
                <v-text-field dense v-model="cpar_header.ref_doc_no_" placeholder="Ref Doc No." label="Doc. No." outlined hide-details class="mb-2" readonly disabled></v-text-field>
              </v-col>
            </v-row>
          </card-expansion>
          <!-- Description -->
          <card-expansion title="Detail" class="mb-3">
            <v-row class="mb-3">
              <v-col>
                <v-tabs v-model="tab">
                  <v-tab>Information</v-tab>
                  <v-tab>Description</v-tab>
                  <v-tab>Attachments</v-tab>
                </v-tabs>
              </v-col>
            </v-row>
            <v-tabs-items v-model="tab">
              <v-tab-item class="pa-1">
                <v-row class="mb-3">
                  <v-col cols="12" md="6">
                    <v-text-field dense v-model="cpar_header.initiator" placeholder="Pemrakarsa" label="Pemrakarsa" outlined hide-details class="mb-2"></v-text-field>
                    <v-select dense v-model="cpar_header.cpar_category_id" placeholder="Kategori" label="Kategori" :items="cpar_categories" item-text="name" item-value="id" outlined hide-details class="mb-2"></v-select>
                    <!-- <v-autocomplete dense v-model="selected_intended_person" placeholder="Ditujukan Kepada" label="Ditujukan Kepada" multiple :items="intended_person" item-text="username" item-value="username" outlined hide-details class="mb-2"></v-autocomplete> -->
                    <v-autocomplete dense v-model="selected_subject" :search-input.sync="search_subject" placeholder="Ditujukan Kepada" label="Ditujukan Kepada" return-object :items="subjects" item-text="description" item-value="code" outlined hide-details class="mb-2" deletable-chips chips small-chips hide-selected :loading="search_subject_loading">
                      <template v-slot:item="{ item }">
                        <v-list-item-avatar color="indigo" class="text-h5 font-weight-light white--text">
                          {{ item.description.charAt(0) }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.description }}</v-list-item-title>
                          <v-list-item-subtitle>{{ item.code }} ({{ item.source }})</v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                    <v-list>
                      <v-list-item v-for="item in cpar_subject" :key="item.code">
                        <v-list-item-avatar color="indigo" class="text-h5 font-weight-light white--text">
                          {{ item.description.charAt(0) }}
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.description }}</v-list-item-title>
                          <v-list-item-subtitle>{{ item.code }} ({{ item.source }})</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn icon @click="deleteSubject(item)" :title="'Delete ' + item.description"><v-icon color="error">mdi-delete</v-icon></v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field dense v-model="cpar_header.delivery_date" placeholder="Delivery Date" label="Delivery Date" type="date" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="cpar_header.art_number" placeholder="Art. Number" label="Art. Number" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="cpar_header.uom" placeholder="Satuan" label="Satuan" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="cpar_header.product_model" placeholder="Model / Produk" label="Model / Produk" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field dense v-model="cpar_header.quantity" placeholder="Jumlah" label="Jumlah" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item class="pa-1">
                <v-row class="mb-3">
                  <v-col>
                    <v-btn text outlined @click="dialogDescAdd = true">Add</v-btn>
                    <v-card elevation="1" class="mt-1">
                      <v-data-table :items="cpar_description" :headers="cpar_description_columns" item-key="line_no_">
                        <template v-slot:[`item.description`]="{ item }">
                          <div style="white-space: pre;">{{ item.description }}</div>
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                          <v-btn x-small icon text @click="editRow(item)" class="mx-1">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                          <v-btn x-small icon text @click="removeRow(item)" class="mx-1">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row class="mb-3">
                  <v-col cols="12" md="12">
                    <v-textarea dense v-model="cpar_header.annotation" placeholder="Keterangan" label="Keterangan" outlined hide-details class="mb-2"></v-textarea>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item class="pa-1">
                <v-row class="mb-3">
                  <v-col cols="8" md="8">
                    <v-text-field append-icon="mdi-upload" @click:append="uploadFile(1)" dense v-model="cpar_header.attachment1" placeholder="Attachment 1" label="Attachment 1" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field append-icon="mdi-upload" @click:append="uploadFile(2)" dense v-model="cpar_header.attachment2" placeholder="Attachment 2" label="Attachment 2" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field append-icon="mdi-upload" @click:append="uploadFile(3)" dense v-model="cpar_header.attachment3" placeholder="Attachment 3" label="Attachment 3" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field append-icon="mdi-upload" @click:append="uploadFile(4)" dense v-model="cpar_header.attachment4" placeholder="Attachment 4" label="Attachment 4" outlined hide-details class="mb-2"></v-text-field>
                    <v-text-field append-icon="mdi-upload" @click:append="uploadFile(5)" dense v-model="cpar_header.attachment5" placeholder="Attachment 5" label="Attachment 5" outlined hide-details class="mb-2"></v-text-field>
                  </v-col>
                  <v-col cols="4" md="4">
                    <v-autocomplete dense v-model="cpar_header.attachment_type1" placeholder="Attachment Type" label="Attachment Type" :items="attachment_types" outlined hide-details class="mb-2"></v-autocomplete>
                    <v-autocomplete dense v-model="cpar_header.attachment_type2" placeholder="Attachment Type" label="Attachment Type" :items="attachment_types" outlined hide-details class="mb-2"></v-autocomplete>
                    <v-autocomplete dense v-model="cpar_header.attachment_type3" placeholder="Attachment Type" label="Attachment Type" :items="attachment_types" outlined hide-details class="mb-2"></v-autocomplete>
                    <v-autocomplete dense v-model="cpar_header.attachment_type4" placeholder="Attachment Type" label="Attachment Type" :items="attachment_types" outlined hide-details class="mb-2"></v-autocomplete>
                    <v-autocomplete dense v-model="cpar_header.attachment_type5" placeholder="Attachment Type" label="Attachment Type" :items="attachment_types" outlined hide-details class="mb-2"></v-autocomplete>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </card-expansion>
        </v-col>
      </v-row>
    </v-container>
    <input type="file" style="display: none" name="fileupload" id="fileupload" ref="reffileupload" v-on:change="handleFileUpload()" />

    <v-dialog v-model="dialogDescAdd" width="600">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Add Deskripsi
        </v-card-title>

        <v-card-text class="pt-3">
          <v-autocomplete v-model="cparDescAdd.line_no_" :items="lineNos" outlined hide-details class="mb-2" dense title="Line No." placeholder="Line No." label="Line No."></v-autocomplete>
          <v-textarea v-model="cparDescAdd.description" outlined hide-details class="mb-2" dense title="Deskripsi" placeholder="Deskripsi" label="Deskripsi"></v-textarea>
          <v-text-field v-model="cparDescAdd.clause" outlined hide-details class="mb-2" dense title="Klausul" placeholder="Klausul" label="Klausul"></v-text-field>
          <v-text-field v-model="cparDescAdd.finding_category" outlined hide-details class="mb-2" dense title="Kategori Temuan" placeholder="Kategori Temuan" label="Kategori Temuan"></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="dialogDescAdd = false, addDescRow()">Save</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDescAdd = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDescEdit" width="600">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Edit Deskripsi
        </v-card-title>

        <v-card-text class="pt-3">
          <v-autocomplete v-model="cparDescEdit.line_no_" :items="lineNos" outlined hide-details class="mb-2" dense title="Line No." placeholder="Line No." label="Line No."></v-autocomplete>
          <v-textarea v-model="cparDescEdit.description" outlined hide-details class="mb-2" dense title="Deskripsi" placeholder="Deskripsi" label="Deskripsi"></v-textarea>
          <v-text-field v-model="cparDescEdit.clause" outlined hide-details class="mb-2" dense title="Klausul" placeholder="Klausul" label="Klausul"></v-text-field>
          <v-text-field v-model="cparDescEdit.finding_category" outlined hide-details class="mb-2" dense title="Kategori Temuan" placeholder="Kategori Temuan" label="Kategori Temuan"></v-text-field>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" text @click="dialogDescEdit = false, updateDescRow()">Save</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogDescEdit = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CommentList from './components/CommentList.vue';

export default {
  components: {
    CommentList,
  },
  data() {
    return {
      id: 0,
      loading: false,
      notFound: false,
      modal1: false,
      modal2: false,
      tab: null,
      dialogDescAdd: false,
      dialogDescEdit: false,
      cpar_header: {},
      cpar_description_columns: [
        { text: "No.", value: "line_no_", sortable: false },
        { text: "Deskripsi", value: "description", sortable: false },
        { text: "Klausul", value: "clause", sortable: false },
        { text: "Kategori Temuan", value: "finding_category", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
      cpar_description: [],
      cpar_subjects: [],
      cpar_sources: [],
      selected_cpar_sources: [],
      cpar_types: ["Baru", "Berulang"],
      intended_person: [],
      selected_intended_person: [],
      cpar_categories: [],
      selected_cpar_categories: [],
      selected_attachment: "",
      attachment_types: [
        "Email",
        "WA",
        "Fax",
        "Surat Jalan",
        "Lainnya"
      ],
      saveControl: false,

      cparDescAdd: {
        line_no_: null,
        description: "",
        clause: "",
        finding_category: "",
      },
      cparDescEdit_idx: null,
      cparDescEdit: {
        line_no_: null,
        description: "",
        clause: "",
        finding_category: "",
      },
      lineNos: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      business_units: [
        'SBUS',
        'SBUP',
      ],
      cpar_subject: [],
      selected_subject: {},
      subjects: [],
      search_subject: "",
      search_subject_loading: false,
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    cpar_header(n, o) {
      this.saveControl = true
    },

    // eslint-disable-next-line no-unused-vars
    dialogDescAdd(n, o) {
      this.cparDescAdd.id = null;
      this.cparDescAdd.line_no_ = null;
      this.cparDescAdd.description = "";
      this.cparDescAdd.clause = "";
      this.cparDescAdd.finding_category = "";
    },

    // eslint-disable-next-line no-unused-vars
    cpar_intended_person(n, o) {
      this.selected_intended_person = [];

      n.forEach(item => {
        this.selected_intended_person.push(item.username)
      });
    },

    // eslint-disable-next-line no-unused-vars
    search_subject(n, o) {
      this.getSubjects(n);
    },
    // eslint-disable-next-line no-unused-vars
    selected_subject(n, o) {
      if (typeof n.code !== 'undefined') {
        let isExist = this.cpar_subject.find((item) => {
          return item.code==n.code;
        })

        if(isExist){
          this.showAlert("warning", n.description + " is already exists!")
        }else{
          n.id = 0;
          this.cpar_subject.push(n)
        }
        this.selected_subject = {};
      }
    }
  },
  methods: {
    async getCPARSources(refresh = false) {
      if (refresh == true)
        this.AxiosStorageRemove("GET", "cpar/sources");
      await this.$axios
        .get("cpar/sources", {
          params: {
            document_type: this.cpar_header.document_type
          }
        })
        .then((res) => {
          this.cpar_sources = res.data.data.results;
        })
    },

    async getIntendedPerson(refresh = false) {
      if (refresh == true)
        this.AxiosStorageRemove("GET", "cpar/intended-person");
      await this.$axios
        .get("cpar/intended-person")
        .then((res) => {
          this.intended_person = res.data.data.results;
        })
    },

    async getSubjects(search = "") {
      this.search_subject_loading = true;
      await this.$axios
        .get("cpar/get-subjects", {
          params: {
            search: search
          }
        })
        .then((res) => {
          this.subjects = res.data.data.results;
          this.search_subject_loading = false;
        })
    },

    async getCategories(refresh = false) {
      if (refresh == true)
        this.AxiosStorageRemove("GET", "cpar/categories");
      await this.$axios
        .get("cpar/categories")
        .then((res) => {
          this.cpar_categories = res.data.data.results;
        })
    },

    async getData(refresh = false) {
      if (refresh == true)
        this.AxiosStorageRemove("GET", "cpar/detail");

      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.id,
        },
      };
      await this.$axios
        .get("cpar/detail", config)
        .then((res) => {
          this.cpar_header = res.data.data.cpar_header;
          this.cpar_description = res.data.data.cpar_description;
          this.cpar_subject = res.data.data.cpar_subject;

          this.getCPARSources();
          this.showLoadingOverlay(false);
          this.loading = false;
          this.notFound = false;
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.notFound = true;
          }
          this.showLoadingOverlay(false);
          this.loading = false;
          this.axiosErrorHandler(error);
        });
    },

    async saveData() {
      this.loading = true;
      this.showLoadingOverlay(true);
      var formData = new FormData();
      formData.append(
        "cpar_header",
        JSON.stringify(this.cpar_header)
      );
      formData.append(
        "cpar_description",
        JSON.stringify(this.cpar_description)
      );
      formData.append(
        "cpar_intended_person",
        JSON.stringify(this.selected_intended_person)
      );
      formData.append(
        "cpar_subject",
        JSON.stringify(this.cpar_subject)
      );


      await this.$axios
        .post("cpar/save", formData)
        .then((res) => {
          this.loading = false;
          this.showLoadingOverlay(false);

          var resData = res.data;

          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);

            this.goBack()
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    uploadFile(sa) {
      this.selected_attachment = sa;
      this.$refs.reffileupload.click();
    },

    handleFileUpload() {
      if (this.selected_attachment == 1) {
        this.cpar_header.attachment1 = "";
      } else if (this.selected_attachment == 2) {
        this.cpar_header.attachment2 = "";
      } else if (this.selected_attachment == 3) {
        this.cpar_header.attachment3 = "";
      } else if (this.selected_attachment == 4) {
        this.cpar_header.attachment4 = "";
      } else if (this.selected_attachment == 5) {
        this.cpar_header.attachment5 = "";
      }

      this.fileupload = this.$refs.reffileupload.files[0];

      this.showLoadingOverlay(true);

      let formData = new FormData();
      formData.append("fileupload", this.fileupload);
      this.$axios
        .post("cpar/upload-attachment", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.showLoadingOverlay(false);
          var resData = res.data;
          if (resData.status == "success") {
            if (this.selected_attachment == 1) {
              this.cpar_header.attachment1 = resData.data.file_src;
            } else if (this.selected_attachment == 2) {
              this.cpar_header.attachment2 = resData.data.file_src;
            } else if (this.selected_attachment == 3) {
              this.cpar_header.attachment3 = resData.data.file_src;
            } else if (this.selected_attachment == 4) {
              this.cpar_header.attachment4 = resData.data.file_src;
            } else if (this.selected_attachment == 5) {
              this.cpar_header.attachment5 = resData.data.file_src;
            }
          } else {
            this.showAlert(resData.status, resData.message);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    addDescRow() {

      // console.log(this.cparDescAdd.description);

      this.cpar_description.push({
        id: null,
        cpar_header_document_no_: this.cpar_header.document_no_,
        description: this.cparDescAdd.description,
        clause: this.cparDescAdd.clause,
        finding_category: this.cparDescAdd.finding_category,
        line_no_: this.cparDescAdd.line_no_
      });

    },

    // eslint-disable-next-line no-unused-vars
    removeRow(item) {
      var i = this.cpar_description.indexOf(item);

      this.cpar_description.splice(i, 1);
    },

    editRow(item) {

      this.cparDescEdit_idx = this.cpar_description.indexOf(item);

      // this.cpar_description.splice(i, 1);

      this.cparDescEdit.id = item.id;
      this.cparDescEdit.line_no_ = item.line_no_;
      this.cparDescEdit.description = item.description;
      this.cparDescEdit.clause = item.clause;
      this.cparDescEdit.finding_category = item.finding_category;

      this.dialogDescEdit = true;
    },

    updateDescRow() {

      // console.log(this.cparDescEdit_idx);

      // console.log(this.cpar_description[this.cparDescEdit_idx]);

      this.cpar_description[this.cparDescEdit_idx].id = this.cparDescEdit.id;
      this.cpar_description[this.cparDescEdit_idx].cpar_header_document_no_ = this.cpar_header.document_no_;
      this.cpar_description[this.cparDescEdit_idx].line_no_ = this.cparDescEdit.line_no_;
      this.cpar_description[this.cparDescEdit_idx].description = this.cparDescEdit.description;
      this.cpar_description[this.cparDescEdit_idx].clause = this.cparDescEdit.clause;
      this.cpar_description[this.cparDescEdit_idx].finding_category = this.cparDescEdit.finding_category;
    },

    deleteSubject(item){
      const i = this.cpar_subject.indexOf(item);
      if(typeof this.cpar_subject[i] != 'undefined'){
        this.cpar_subject.splice(i);
      }
    }
  },
  mounted() {
    this.id = this.$route.query.id;

    this.getData();
    this.getIntendedPerson();
    this.getCategories();
  },
};
</script>
